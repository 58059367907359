import { Grid, Typography } from '@mui/material';
import { Heading6 } from 'components/headings/heading6/heading6';
import { FC, useState } from 'react';
import './SubscriptionPlanCard.scss';
import { SubscribedPlan } from 'interfaces/subscription';
import { getApiHrefByRel } from 'helpers/api-links-helper';

import { SvgCtaContent, SvgIcons } from 'shared';
import { Alignment } from 'static';
import { useTranslation } from 'react-i18next';
import { axiosGet } from 'helpers';
import { Button } from 'components/clickers/button/button';
import moment from 'moment';

interface Props {
  subscription: SubscribedPlan;
}

const SubscriptionPlanCard: FC<Props> = ({ subscription }) => {
  const { Status, Name, Description, Links, PeriodDays, ExpiresAt } =
    subscription;

  const friendlyExpires = !!ExpiresAt
    ? moment(ExpiresAt).format('HH:mm DD.MM.YYYY')
    : null;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const handlePay = async () => {
    setIsLoading(true);
    const selfLink = getApiHrefByRel(Links, 'self');
    if (!selfLink) {
      console.error(
        'Cannot continue with Payment because there is no self link in links',
        { Links },
      );
      return;
    }
    const response = await axiosGet<SubscribedPlan>(selfLink);

    const payLink = getApiHrefByRel(response?.data?.Links ?? [], 'pay');

    if (payLink) {
      window.location.href = payLink;
    }
  };

  return (
    <Grid className="subscription-list-item" container alignItems={'start'}>
      <Grid container direction="column">
        <Grid item>
          <Heading6>{Name}</Heading6>
          <Typography component="p" className="secondary-text">
            {Description}
          </Typography>
          {!!PeriodDays && (
            <Typography component="p" className="secondary-text">
              <b>{t('Period')}</b>: {PeriodDays} {t('days')}
            </Typography>
          )}
          {!!friendlyExpires && (
            <Typography component="p" className="secondary-text">
              <b>{t('Expires at')}</b>: {friendlyExpires}
            </Typography>
          )}
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid>
            <Heading6
              style={{
                paddingTop: '10px',
                color:
                  Status === 'Active'
                    ? '#358935'
                    : Status === 'Application rejected'
                    ? '#eb1d1d'
                    : '#df5e00',
              }}
            >
              {Status}
            </Heading6>
            {Status === 'Awaiting payment' && (
              <Typography component={'span'} sx={{ fontSize: 11 }}>
                <i>
                  {t('Payment needs to be completed')} <br />
                  {t('within 15 minutes')}
                </i>
              </Typography>
            )}
          </Grid>
          {Status === 'Awaiting payment' && (
            <>
              <Button
                className="subscription-list-item-pay-button"
                onClick={handlePay}
                loading={isLoading}
                disabled={isLoading}
              >
                <>
                  {isLoading ? (
                    <>{t('Loading')}...</>
                  ) : (
                    <>
                      <span style={{ paddingRight: '5px' }}>{t('Pay')} </span>
                      <SvgCtaContent
                        iconName={SvgIcons.ArrowRight}
                        iconPosition={Alignment.Right}
                        iconWidth={15}
                      />
                    </>
                  )}
                </>
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPlanCard;
